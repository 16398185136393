<template> 
  <div class="card shadow-sm bg-white border p-8">
    <div class="text-start">
      <h3 class="font-bold">Criar Variação</h3>
      <span class="text-sm text-gray-500">Preencha as informações abaixo e clique no botão <b>criar</b> para salvar
      as alterações.
      </span>
    </div>
      <div class="flex lg:flex-row flex-col mt-5">
      <div class="w-full p-2">
        <Input type="default" id="nome" v-model="variacao.nome" label="Nome Variação" placeholder="Nome da Variação" />
      </div>
      <div class="w-full p-2">
        <Input type="number" placeholder="Ordem da Variação" id="ordem" v-model="variacao.ordenar" label="Ordem da Variação"/>
      </div>
      </div>
    <div class="flex mb-6 justify-end mt-3">
        <Button @click="create" color="primary" :text="`Criar`"></Button>
    </div>
    
  </div>
  </template>
  
  <script>
  import Button from "../../components/Elements/Button.vue";
  import { inject, ref } from "vue";
  import { POST } from "../../services/api";
  import { useRouter } from "vue-router";
  import { isEmpty } from "../../services/validate";
  import Input from "../../components/Elements/Input.vue";
  
  export default {
    name: "CriarSetores",
    components: {
      Button,
      Input,
    },
  
    data() {
      return {
        
      };
    },
  
    setup() {
      const router = useRouter();
      const alert = inject("alert");
      const loader = inject("loading");
      const data = ref([]);
      const variacao = ref({
        nome: "",
        ordenar: "1",
      });
  
      const create = async () => {
        const validateNome = isEmpty(variacao.value.nome);
        const validateOrdenar = isEmpty(variacao.value.ordenar);
        
        if (validateNome || validateOrdenar) {
          alert.open("Atenção!", validateNome, "warning"); 
          alert.open("Atenção!", validateOrdenar, "warning"); 
        } else {
          loader.open();
          try {
            await POST("variacao", variacao.value);
            alert.open(
              "Sucesso!",
              `Variação <b>${variacao.value.nome}</b> criado com sucesso!`,
              "success"
            );
            data.value.push(variacao.value);
            router.push("/variacao");
            loader.close();
          } catch (e) {
            alert.open("Atenção!", e, "danger");
            loader.close();
          }
        }
      };
  

      return { data, router, create, variacao };
    },
  };
  </script>
  
  <style>
  .btn{
      width: 100%;
      align-items: center;
      justify-content: center;
    }
  </style>
  